import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorStyles } from './errorStyles';

const ErrorPage = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/');
    };

    return (
        <ErrorStyles.Container>
            {/* <div className='text-4xl'>Grid Connect Dashboard</div> */}
            <ErrorStyles.Title>Oops! Something went wrong.</ErrorStyles.Title>
            <ErrorStyles.Message>We're sorry, but the page you're looking for doesn't exist.</ErrorStyles.Message>
            <ErrorStyles.HomeButton onClick={handleButtonClick}>Go to Home</ErrorStyles.HomeButton>
        </ErrorStyles.Container>
    );
};

export default ErrorPage;