import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload, FileUploadHandlerEvent, ItemTemplateOptions } from 'primereact/fileupload';
import { showToast } from './Utility';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { IoMdClose } from "react-icons/io";
import { UploadFileService } from '../service/uploadService';
import { Message } from 'primereact/message';
import { EStyles } from '../components/events/EventsForm/eventsformStyles';

type CsvReaderProps = {
  onPublishEvent?: any;
  setVisible?: any;
  setLoader?: any;
};

const CSVReader: FunctionComponent<CsvReaderProps> = ({
  onPublishEvent,
  setVisible,
  setLoader,
}) => {

  const toast = useRef<Toast>(null);
  const [formValue, setFormvalue] = useState<any>('')
  const [fileNames, setFileNames] = useState<{ [key: string]: string }>({});
  const [fileValidations, setFileValidations] = useState<{ [key: string]: boolean }>({});
  const [hasInvalidFiles, setHasInvalidFiles] = useState(false);
  const [totalSize, setTotalSize] = useState(0);

  const uploadFile = async (file: File, fileExtension: string, fileName: string, formValue: any) => {
    if (fileExtension === 'csv') {
      try {
        await UploadFileService(file, fileExtension, fileName || file.name, formValue);
        setVisible(false);
        onPublishEvent('uploaded', fileName || file.name);
      } catch (error: any) {
        setVisible(false);
        onPublishEvent('failed', fileName || file.name);
        setLoader(false);
      }
    }  else {
      showToast(toast, 'error', 'Unsupported file type', '', false);
    }
  
  };
  
  const checkCsvFormat = (csvData: string): boolean => {
    const lines = csvData.split('\n').filter(line => line.trim() !== '');
  
    if (lines.length <= 2) {
      return false;
    }
    lines.shift();
  
    const headerLine:any = lines.shift();
    const headers = headerLine.split(',').map((header:any) => header.trim());

    const expectedHeaders = ['Date(MM/DD/YYYY)', 'Start Hour(0-23)', 'Start Min(0-59)', 'Duration(Min)', 'Value'];
    
    const headersMatch = expectedHeaders.every((header, index) => headers[index] === header);
  
    if (!headersMatch) {
      return false;
    }
  
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const fields = line.split(',').map(field => field.trim());
  
      if (fields.length !== expectedHeaders.length) {
        return false;
      }
  
      const dateStr = fields[0];
      const dateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/\d{4}$/;
      if (!dateRegex.test(dateStr)) {
        return false;
      }
  
      const startHourStr = fields[1];
      const startHour = parseInt(startHourStr, 10);
      if (isNaN(startHour) || startHour < 0 || startHour > 24) {
        return false;
      }
  
      const startMinStr = fields[2];
      const startMin = parseInt(startMinStr, 10);
      if (isNaN(startMin) || startMin < 0 || startMin > 59) {
        return false;
      }
  
      const durationStr = fields[3];
      const duration = parseInt(durationStr, 10);
      if (isNaN(duration) || duration <= 0) {
        return false;
      }
  
      const valueStr = fields[4];
      const value = parseFloat(valueStr);
  
      if (isNaN(value) || value < 0) {
        return false;
      }
    }
  
    return true;
  };
  
  const onUpload = async (event: FileUploadHandlerEvent) => {
    const files = event.files;
    setLoader(true);
    files.forEach((file) => {
      const key = getFileKey(file);   
      const fileExtension: any = file.name.split('.')[1];
      const fileName = fileNames[key] || file.name.split('.')[0];      
      uploadFile(file, fileExtension, fileName, formValue);
    });
  };

  const onTemplateRemove = (file: File, callback: Function) => {
    setTotalSize(totalSize - file.size);
    callback();
    setFormvalue(null)
    setFileValidations({})
  }

  const handleFormChange: Function = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormvalue(e);
  }

  const getFileKey = (file: File) => `${file.name}-${file.lastModified}`;

  useEffect(() => {
    const anyInvalid = Object.values(fileValidations).includes(false);
    setHasInvalidFiles(anyInvalid);
  }, [fileValidations]);

  const onSelect = (event: any) => {
    const selectedFiles = event.files; 
  
    setFileValidations({});
  
    selectedFiles.forEach((file: File) => {
      validateFile(file);
    });
  };

  const onClear = () => {
    setFileValidations({});
  };


  const validateFile = (file: File) => {
    const fileExtension:any = file.name.split('.').pop()?.toLowerCase();
    const key = getFileKey(file);

    const reader = new FileReader();

    reader.onload = async (e) => {
      const fileContents = e.target?.result;

      const isValid = await validateFileContents(fileContents, fileExtension);

      if (!isValid) {
        showToast(toast, "error", "File Validation Error", "Please check the file", false)
        setLoader(false);
      }

      setFileValidations((prevValidations) => ({
        ...prevValidations,
        [key]: isValid,
      }));
    };

    if (fileExtension === 'csv') {
      reader.readAsText(file);
    } else {
      setFileValidations((prevValidations) => ({
        ...prevValidations,
        [key]: false,
      }));
    }
  };

  const validateFileContents = async (fileContents: any, fileExtension: string): Promise<boolean> => {
    if (fileExtension === 'csv') {
      const csvData = fileContents as string;
      return checkCsvFormat(csvData);
    } else {
      return false;
    }
  };

const onRemove = (event: any) => {
  const removedFile = event.file;
  const key = getFileKey(removedFile);

  setFileValidations((prevValidations) => {
    const { [key]: _, ...rest } = prevValidations;
    return rest;
  });
};


const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
  const file = inFile as File;

  if (!file || !file.name) {
    return null;
  }

  const key = getFileKey(file);
  const isValid = fileValidations[key];
  const fileName =
    fileNames[key] !== undefined
      ? fileNames[key]
      : file.name.split('.')[0];
  
  const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileNames((prevFileNames:any) => ({
      ...prevFileNames,
      [key]: e.target.value,
    }));
  };
  return (<>
  
    <div className="card grid justify-content-evenly m-0">
      <Toast ref={toast} className='text-left'/>
      <div className="col-5">
        <span className="flex flex-column text-left p-ml-3">
          <div className="text-left">File Name :</div>
         <InputText
              data-testid="area"
              id={`area-${key}`}
              value={fileName}
              onChange={handleFileNameChange}
              className="col-12 p-2"
            />
          <small>{new Date().toLocaleDateString()}</small>
        </span>
      </div>
      <div className="col-4 flex flex-column">
        <div className="text-left">Remarks :</div>
        <InputText
          data-testid="remarks"
          id="remarks"
          value={formValue}
          onChange={(e) => handleFormChange(e.target.value)}
          className="col-12 p-2"
          placeholder='Remarks (Optional)'
        />
      </div>
      <div className="flex align-items-center">
        <Button
          icon={<IoMdClose />} rounded outlined severity="danger" aria-label="Cancel"
          onClick={() => onTemplateRemove(file, props.onRemove)} className='h-2rem'
        />
      </div>
      {isValid === false && (
        <Message severity="error" text="Invalid file contents, Please check the file." />
      )}
      </div>
      </>
  );
};
  return (
    <>
    <EStyles.EventsFormContainer className='mx-3'>
      <FileUpload
        accept=".csv"
        maxFileSize={3145728}
        customUpload
        onSelect={onSelect}
        onRemove={onRemove}
        uploadHandler={onUpload}
        onClear={onClear}
        progressBarTemplate
        cancelOptions={{ className: 'hidden' }}
        uploadOptions={{ className: 'ford-bg-primary' }}
        itemTemplate={itemTemplate}
        emptyTemplate={<div className="p-d-flex p-ai-center p-jc-center">Drag and drop the file to upload</div>}
        pt={{
          chooseButton: { className: 'ford-bg-primary' },
          buttonbar: { className: 'justify-content-end' },
          badge: { root: { className: 'hidden' } },
          uploadButton:{root:{className:`${hasInvalidFiles ? 'p-disabled': ''}`}}
        }}
      />
      </EStyles.EventsFormContainer>
      </>
  );
};

export default CSVReader