import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { useFetchTariffs } from "../../Utils/useFetchTariff";
import axios from "axios";
import { LogsTable } from "../../Utils/fileLogTable";
import CSVReader from "../../Utils/fileUpload";
import { showToast } from "../../Utils/Utility";

export const TariffsLog: React.FC = () => {
    const { tariffLogs, isLoading, setLoadTariffs,publishedEventId ,setPublishedEventId,setIsLoading } = useFetchTariffs();
    const [visible, setVisible] = useState(false);
    const toast = useRef<Toast>(null);

    const handleChildEvent = (requestId: string, filename: string) => {
        setLoadTariffs(true);
        setPublishedEventId(requestId + filename);
    };
    
    useEffect(() => {
        if (publishedEventId) {
            if (publishedEventId.includes('uploaded')) {
                showToast(toast, "success", "Complete!", `${publishedEventId.split('uploaded')[1]} has been uploaded successfully!`, false);
            } else if (publishedEventId.includes('failed')) {
                showToast(toast, "error", `Failed to Upload: ${publishedEventId.split('failed')[1]}`, `File has been already exists , Please change the file name`, false);
            } else if (publishedEventId.includes('Cancelled')) {
                showToast(toast, "warn", "Complete!", `${publishedEventId.split('Cancelled')[1]} has been deleted successfully!`, false);
            }
        }
    }, [publishedEventId]);

    const handleDownload = async (rowData: any) => {
        const filename = rowData?.metadata?.filenameInBucket;
        try {
            const response = await axios.get(`https://tariff-service-345158162856.us-central1.run.app/v1/tariffs/download?folder=${rowData.metadata?.folder}&filename=${filename}&version=${rowData?.generation}`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Download failed', error);
        }
    };

    const hideDialog = () => {
        setVisible(false);
    };

    return (
        <>
            <Toast ref={toast} position="top-right" />
            <LogsTable tariffLogs={tariffLogs} handleDownload={handleDownload} handleChildEvent={handleChildEvent} pageTitle="TARIFFS" setVisible={setVisible} />
            <Dialog
                visible={visible}
                style={{ width: '60vw' }}
                className={"p-fluid"}
                header="Upload Variable Tariff Rate"
                modal
                onHide={hideDialog}
                maximizable={window.innerWidth < 768 ? false : true}
                maximized={window.innerWidth < 768 ? true : false}
                dismissableMask
                pt={{ header: { style: { padding: "10px 25px 10px 25px" } }, headerTitle: { style: { fontWeight: 'bolder' } } }}>
                <CSVReader
                    onPublishEvent={handleChildEvent}
                    setVisible={setVisible}
                    setLoader={setIsLoading}
                />
            </Dialog>
        </>
    );
};