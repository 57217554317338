import React, { useState } from 'react';
import image from '../../assets/images/fordgridPoster.svg'
import { TbChevronRight } from 'react-icons/tb';
import scheduleIcon from '../../assets/images/scheduleIcon.svg'
import uploadIcon from '../../assets/images/uploadIcon.svg'
import fordIcon from '../../assets/images/fordLogo.svg'
import { DStyles } from './infoStyles';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import CSVReader from '../../Utils/fileUpload';

const Dashboard = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const hideDialog = () => {
    setVisible(false);
}

  return (
    <div className="grid grid-nogutter">
      <DStyles.PageContainer className='pt-2'>
        <DStyles.PageHeader className='text-3xl m-3'>FORD GRID CONNECT</DStyles.PageHeader>
        <DStyles.TitleContent className="p-card-content">
          <p className="m-0 text-sm p-0">
            Platform for integrating Utilities (e.g. DTE, DUKE) with Ford Pro and Model-e for optimized charging and data sharing
          </p>
        </DStyles.TitleContent>
        <DStyles.ImageContainer src={image} alt='fordGridPoster' />
        <DStyles.ButtonContainer className='flex sm:hidden'>
        <DStyles.CustomButton data-testid="events-btn" className='' onClick={() => navigate('/events')}><img src={scheduleIcon} alt="generateIcon" className="mx-2" />Demand Response</DStyles.CustomButton>
        <DStyles.CustomButton data-testid="upload-btn" onClick={() => setVisible(true)}><img src={uploadIcon} alt="generateIcon" className="mx-2" />Upload Variable Tariff</DStyles.CustomButton>
        </DStyles.ButtonContainer>
        <DStyles.PageHeader2 className='text-lg font-bold'>USE CASES</DStyles.PageHeader2>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Demand Response - Load Reduction</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Dynamic Price Managed Charging - Load Shifting</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Automated ToU - Load Avoidance</span></DStyles.TitleContent>

        <DStyles.PageHeader2 className='text-lg font-bold'>PROTOCOLS</DStyles.PageHeader2>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>OpenADR 2.0b</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>IEEE 2030.5*</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>ISO 15118-20*</span></DStyles.TitleContent>
      </DStyles.PageContainer>

      <DStyles.PageContainer className='pt-2'>
        <DStyles.ButtonContainer className='hidden sm:flex'>
          <DStyles.CustomButton data-testid="events2-btn" className='mr-4' onClick={() => navigate('/events')}><img src={scheduleIcon} alt="generateIcon" className="mr-2" />Demand Response</DStyles.CustomButton>
          <DStyles.CustomButton data-testid="upload-btn" onClick={() => setVisible(true)}><img src={uploadIcon} alt="generateIcon" className="mr-2" />Upload Variable Tariff</DStyles.CustomButton>
        </DStyles.ButtonContainer>

        <DStyles.PageHeader2 className='text-lg font-bold'>EV DRIVER / FLEET</DStyles.PageHeader2>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Enroll EV Drivers, manage charging schedules</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Earn incentive from utility</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>No impact to daily mobility needs</span></DStyles.TitleContent>
        <DStyles.PageHeader2 className='text-lg font-bold'>UTILITY AGGREGATORS</DStyles.PageHeader2>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Single interface for utilities to gain access to Ford's EVs as grid resources and get data</span></DStyles.TitleContent>
        <DStyles.PageHeader2 className='text-lg font-bold'>FEATURES</DStyles.PageHeader2>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Generate DR via API (External) & Email</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Consume DR events via UI </span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>User & Authz Management via UI console</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>DR Workflow: Schedule, Generate, Approve, Publish</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>DR Reports to Utility</span></DStyles.TitleContent>
        <DStyles.TitleContent className='text-sm'><TbChevronRight /><span>Multi-VTN to Single Ford VEN</span></DStyles.TitleContent>
        <DStyles.Container2>
          <div className='text-xs'>* - Future Capabilities.
            <img src={fordIcon} alt='fordIcon' className='ml-3' />
          </div>
        </DStyles.Container2>
      </DStyles.PageContainer>
      <Dialog
                        visible={visible}
                        style={{ width: '60vw' }}
                        className={"p-fluid"}
                        header="Upload Variable Tariff Rate"
                        modal
                        onHide={hideDialog}
                        maximizable={window.innerWidth < 768 ? false : true}
                        maximized={window.innerWidth < 768 ? true : false}
                        dismissableMask
                        pt={{ header: { style: { padding: "10px 25px 10px 25px" } }, headerTitle: { style: { fontWeight: 'bolder' } } }}>
                        <CSVReader
                        />
                    </Dialog>
    </div>
  )
}

export default Dashboard;