import React, { useState, useEffect, useCallback, useRef, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import { useResponsiveTable } from '../../../Utils/useResponsiveTable';
import { EventLogService } from '../../../service/eventLogService';
import { createPaginatorTemplate } from '../../../Utils/paginationTemplate';
import { ClipLoader } from 'react-spinners';
import { LStyles } from './eventlogsStyles';
import collapseIcon from '../../../assets/images/collapseIcon.svg';
import generateIcon2 from '../../../assets/images/generateIcon2.svg';
import { responseAcknowledgedTemplate, responseAcknowledgedTemplateForEvent, responseCodeTemplate, responseCodeTemplateForEvent, responseCreatedDateTemplateForEvent, responseEndDateTemplate, responseEndDateTemplateForEvent, responsePayloadTemplate, responsePayloadTemplateForEvent, responseStartDateTemplate, responseStartDateTemplateForEvent } from '../../../Utils/logTemplates';
import { RequestIdTemplate } from '../EventsIdTemplate/events_id_template';
import { EventDto } from '../../../types/event';
import ResponseObjectTemplate from '../EventsOverlay/events_overlay';
import { EventDeliveryService } from '../../../service/eventDeliveryService';
import { showToast } from '../../../Utils/Utility';
import { HttpStatusCode } from 'axios';
import { Dialog } from 'primereact/dialog';
import { EventsForm } from '../EventsForm/events_form';

export const EventsLog: FunctionComponent = () => {
    const navigate = useNavigate();
    const [eventLogs, setEventLogs] = useState<any[]>([]);
    const [visible, setVisible] = useState(false);
    const [loadEvents, setLoadEvents] = useState(true);
    const [expandedRows, setExpandedRows] = useState<any>(null);
    const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>({});
    const [rowData, setRowData] = useState<any>({});
    const toast = useRef<Toast>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [publishedEventId, setPublishedEventId] = useState<string>();
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageLinks, setPageLinks] = useState<any>();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const { scrollWidth, tableSize } = useResponsiveTable();

    const fetchEvents = useCallback(async (page: number, limit: number) => {
        setLoading(true);
        try {
            const response = await EventLogService(page, limit);
            if (response?.status === 200) {
                const records = response.data.data.data;
                const total = response.data.data.totalCount;
                const pagelink = response.data.data.pageLinks;
                setPageLinks(pagelink);

                if (Array.isArray(records)) {
                    setEventLogs(records);
                    setTotalRecords(total);
                } else {
                    setEventLogs([]);
                    console.error('Expected an array for records, but got:', typeof records);
                }
            } else if (response.status === 403) {
                navigate('/login');
                console.error('Error fetching events:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching events:', error);
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    const expandTemplate = (data: EventDto) => {
        const isLoading: any = loadingRows[data.id];
        return (
            <LStyles.TemplateContainer>
                <DataTable value={data?.delivery_logs} stripedRows style={{ border: "none" }} emptyMessage="No records found." loading={isLoading} size={tableSize} className="sm:text-xs md:text-xs lg:text-sm xl:text-md">
                    <Column field="consumer_name" header="Name" sortable style={{ width: '200px' }}
                        headerStyle={{ backgroundColor: 'lightblue' }} />
                    <Column field="response_acknowledged" header="Acknowledgement" sortable
                        style={{ width: '200px' }}
                        headerStyle={{ backgroundColor: 'lightblue' }} body={responseAcknowledgedTemplateForEvent} />
                    <Column field="response_code" header="Response Code" sortable style={{ width: '200px' }}
                        headerStyle={{ backgroundColor: 'lightblue' }} body={responseCodeTemplateForEvent} />
                    <Column field="response_object" header="Payload Sent" body={ResponseObjectTemplate} style={{ width: '200px' }}
                        headerStyle={{ backgroundColor: 'lightblue' }} />
                </DataTable>
            </LStyles.TemplateContainer>
        );
    };


    useEffect(() => {
        if (loadEvents) {
            fetchEvents(page, rows);
           setLoadEvents(false);
           setIsLoading(false)
        }
        if (publishedEventId) {
            showToast(toast, "success", "Event Published!", `Id : ${publishedEventId}`, true);
        }
    }, [loadEvents, fetchEvents, isLoading, publishedEventId]);

    useEffect(() => {
        fetchEvents(page, rows);
    }, [page, rows, fetchEvents]);

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        setPage(event.page + 1);
    };

    const paginationTemplate = createPaginatorTemplate(page, rows, totalRecords, pageLinks, setPage, setRows);

    const renderRequestIdTemplate = (rowData:string) => {
        return <RequestIdTemplate rowData={rowData} type="event" />;
    };

    const onRowCollapse = () => {
        setExpandedRows(null);
    };

    const fetchDeliveryLog = async (eventDto: string) => {
        setLoadingRows(prevState => ({ ...prevState, [eventDto]: true }));

        try {
            const response = await EventDeliveryService(eventDto);
            if (response?.status === 200) {
                eventLogs.forEach((item) => {
                    if (item.id === eventDto) {
                        item.delivery_logs = response?.data?.data;
                    }
                });
            } else if (response.status === HttpStatusCode.Forbidden) {
                navigate("/login");
                showToast(toast, "error", "Oops!", "Error Fetching Delivery Log", false);
            } else {
                console.log("Error Fetching Delivery Log " + response.data.message);
            }
        } catch (error) {
            showToast(toast, "error", "Oops!", "Error Fetching Delivery Log", false);
        } finally {
            setLoadingRows(prevState => ({ ...prevState, [eventDto]: false }));
        }
    };


    const onRowToggle = async (event: { data: any }) => {
        const expanded = event?.data;
        setExpandedRows(expanded);

        if (expanded?.length > 0) {
            const expandedRowId = expanded[expanded?.length - 1]?.id;
            if (!rowData[expandedRowId]) {
                try {
                    const apiData = await fetchDeliveryLog(expandedRowId);
                    setRowData((prevData: object) => ({ ...prevData, [expandedRowId]: apiData }));
                } catch (error) {
                    console.error("Error fetching delivery logs:", error);
                }
            }
        }
    };
    
    const hideDialog = () => {
        setVisible(false);
    };

    const handleChildEvent = (requestId: string) => {
        setLoadEvents(true);
        setPublishedEventId(requestId)
    };
    
    return (
        <>
            {isLoading ? (
                <div className="loader">
                    <ClipLoader color="var(--primary-ui)" loading={isLoading} size={100} aria-label="Publishing" data-testid="loader" className="loader-img" />
                </div>
            ) : (
                <LStyles.EventsLogContainer>
                    <LStyles.HeaderContainer>
                        <Toast ref={toast} position="top-right" />
                        <LStyles.PageHeader>PUBLISHED EVENTS</LStyles.PageHeader>
                        <LStyles.ButtonContainer className="m-2">
                            <LStyles.CustomButton onClick={() => onRowCollapse()}>
                                <img src={collapseIcon} alt="generateIcon" className="mr-2" />
                                Collapse All
                            </LStyles.CustomButton>
                            &nbsp;
                            <LStyles.CustomButton onClick={() => setVisible(true)} data-testid="generate-btn">
                                <img src={generateIcon2} alt="generateIcon" className="mr-2" />
                                Generate Event
                            </LStyles.CustomButton>
                        </LStyles.ButtonContainer>
                    </LStyles.HeaderContainer>
                    <LStyles.DataTableContainer>
                        <LStyles.TableContainer>
                            <ScrollPanel style={{ width: scrollWidth }}>
                                <DataTable
                                    value={eventLogs}
                                    loading={loading}
                                    paginator
                                    paginatorTemplate={paginationTemplate}
                                    rows={rows}
                                    totalRecords={totalRecords}
                                    expandedRows={expandedRows} onRowToggle={onRowToggle}
                                    rowExpansionTemplate={expandTemplate}
                                    first={first}
                                    onPage={onPageChange}
                                    filterDisplay="menu"
                                    sortField="rowId"
                                    sortOrder={-1}
                                    rowsPerPageOptions={[5, 10, 20, 120]}
                                    emptyMessage="No events found"
                                    className={`dataTableClass sm:text-xs md:text-xs lg:text-sm xl:text-md fadein animation-duration-100`}
                                >
                                    <Column expander headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="created_at" sortable field="created_at" header="Created At"
                                        headerStyle={{ backgroundColor: 'lightgray' }} body={responseCreatedDateTemplateForEvent} />
                                    <Column key="signal_name" sortable field="signal_name" header="Signal Name"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="ven_id" sortable field="ven_id" header="Target"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column key="start_time" sortable field="payload?.start_time" header="Event Start"
                                        headerStyle={{ backgroundColor: 'lightgray' }} body={responseStartDateTemplateForEvent} />
                                    <Column key="end_time" sortable field="end_time" header="Event End"
                                        headerStyle={{ backgroundColor: 'lightgray' }} body={responseEndDateTemplateForEvent} />
                                    <Column key="signal_payload" sortable field="signal_payload" header="Signal Payload"
                                        headerStyle={{ backgroundColor: 'lightgray' }} body={responsePayloadTemplateForEvent}/>
                                    <Column key="area_code" sortable field="area_code" header="Area"
                                        headerStyle={{ backgroundColor: 'lightgray' }} />
                                    <Column
                                        key="request_id"
                                        field="request_id"
                                        headerStyle={{ backgroundColor: "lightgray" }}
                                        body={renderRequestIdTemplate}
                                    />
                                </DataTable>
                            </ScrollPanel>
                        </LStyles.TableContainer>
                    </LStyles.DataTableContainer>
                    <Dialog
                        visible={visible}
                        style={{ width: '50vw' }}
                        className={"p-fluid"}
                        header="Generate Event"
                        modal
                        onHide={hideDialog}
                        maximizable={window.innerWidth < 768 ? false : true}
                        maximized={window.innerWidth < 768 ? true : false}
                        dismissableMask
                        pt={{ header: { style: { padding: "10px 25px 10px 25px" } }, headerTitle: { style: { fontWeight: 'bolder' } } }}>
                        <EventsForm
                            onPublishEvent={handleChildEvent}
                            setVisible={setVisible}
                            setLoader={setIsLoading}
                        />
                    </Dialog>
                </LStyles.EventsLogContainer>
            )}
        </>
    );
};