import axios from 'axios';

const BFF_URL = window.__RUNTIME_CONFIG__?.REACT_APP_BFF_URL || 'http://localhost:8080';

const axiosInstance = axios.create({
  baseURL: `${BFF_URL}/grid-connect/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const successHandler = (response: any, toast: any) => {
  const statusCode = response.status;
  if (statusCode === 403) {
    window.location.replace('/');
    return response
  }
  if (statusCode === 401) {
    return Promise.reject(response);
  } else if (statusCode === 200 || statusCode === 201) {
    return response;
  } else if (statusCode === 400) {
    toast.current.show({
      severity: 'warn',
      summary: 'Warning',
      detail:
        response?.data.error?.error ??
        response?.data?.error?.message ??
        'Unknown error',
      life: 3000,
    });
    return response;
  } else {
    window.location.replace('/error');
    return Promise.reject(new Error('Unexpected status code'));
  }
};

export const errorHandler = (error: any, toast: any) => {
  const statusCode = error.response?.status;
  const apiName = (error.config.apiName || 'Unknown') + ' API';
  if (statusCode === 403) {
    window.location.replace('/');
    return Promise.reject(error);
  } 
  if (statusCode === 401) {
    return Promise.reject(error);
  } else if (statusCode === 400) {
    toast.current.show({
      severity: 'warn',
      summary: 'Warning',
      detail:
        error?.response?.data.error?.error ??
        error.response?.data?.error?.message ??
        'Unknown error',
      life: 3000,
    });
    return Promise.reject(error);
  } else {
    window.location.replace('/error');
    return Promise.reject(error);
  }
};

export const setupAxiosInterceptors = (toast: any) => {
  axiosInstance.interceptors.response.use(
    (response) => successHandler(response, toast),
    (error) => errorHandler(error, toast)
  );
};

export default axiosInstance;
