import React, {FC, useMemo, useState} from 'react';
import { IconContext } from 'react-icons';
import { SidebarData } from './sidebardata';
import { useNavigate } from 'react-router-dom';
import {SStyles} from './sidebarStyles';

export const Sidebar: FC = () => {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const iconContextValue = useMemo(() => ({size:'2.25rem'}), []);

    return (
        <IconContext.Provider value={iconContextValue}>
            <SStyles.SidebarContainer>
                <SStyles.SidebarWrap>
                    {SidebarData.map((item) => {
                        return <SStyles.SidebarLink key={item.title} onMouseOver={()=>setHover(true)} onClick={()=>navigate(item.path)} className='sm:justify-content-center'>
                                    <SStyles.SidebarLinkWrapper>
                                        <SStyles.SidebarIcon $hover={hover}>{item.icon}</SStyles.SidebarIcon>
                                        <SStyles.SidebarLabel className='p-1 text-center'>{item.title}</SStyles.SidebarLabel>
                                    </SStyles.SidebarLinkWrapper>
                                </SStyles.SidebarLink>
                    })}
                </SStyles.SidebarWrap>
                {/* <SidebarFooter> &nbsp; &copy; 2024 &nbsp; Ford Motor Company</SidebarFooter> */}
            </SStyles.SidebarContainer>
        </IconContext.Provider>
    );
};